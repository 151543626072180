.speakers {
  margin-bottom: 4rem;
  width: 100%;
  max-width: var(--content-width);

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      text-align: center;
    }

    h1 {
      margin: 0;
    }

    .add-button {
      height: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;

    @media (max-width: 768px) {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
  }
}
