.speaker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  max-width: 100%;

  @media (max-width: 1200px) {
    padding: 0 10%;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 1200px) {
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 1rem;

      width: fit-content;
    }

    h2 {
      margin: 0 0 0.25rem 0;
    }

    &__image-container {
      display: inline-block;

      margin: 2rem;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      overflow: hidden;

      @media (max-width: 768px) {
        margin: 0 0 2rem 0;
        width: 150px;
        height: 150px;
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__info {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 2rem;

      @media (min-width: 1200px) {
        justify-content: flex-start;
      }

      @media (max-width: 768px) {
        align-items: center;
        margin: 0;
        width: 100%;
      }

      &__name {
        margin: 0;
        font-size: 3rem;

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }

      &__rates {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1rem 0;
        font-size: 1.5rem;

        @media (max-width: 768px) {
          flex-direction: row;
          align-items: flex-end;
        }

        &__label {
          font-weight: 500;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &__value {
          font-weight: 300;

          @media (max-width: 768px) {
            font-size: 1.5rem;
          }

          &__not-available {
            font-weight: 200;
          }
        }
      }

      &__topics {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 768px) {
          align-items: center;
        }

        &__label {
          font-weight: 500;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &__list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 0;

          padding: 0;
          list-style: none;

          &__item {
            margin: 0.25rem;
            border: 2px solid var(--accent-color);
            border-radius: 100px;
            background-color: #fff;
            padding: 0.25rem 1rem;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  &__profile {
    @media (min-width: 1200px) {
      flex: 2;
    }

    &__bio {
      font-size: 1.25rem;
      text-align: justify;

      &__label {
        font-weight: 500;
      }
    }

    &__links {
      &__label {
        font-weight: 500;
      }

      &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;

        padding: 0;
        list-style: none;

        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__item {
          &__link {
            color: var(--accent-color);
            font-weight: 500;
            font-size: 1.5rem !important;
            font-family: monospace;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
