.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 0;
  border: none;

  border: none;
  background-color: #ffffff00;
  padding: 0 1rem;
  color: var(--accent-color);
  font-weight: 500;
  font-size: 2rem;
  font-family: var(--font-family-secondary);
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    font-size: 2.5rem;

    &--active {
      background-color: var(--accent-color);
      color: #fff;
    }
  }

  &:hover {
    background-color: #ffffff80;
    color: black;
  }

  &--active {
    border-top: 3px solid var(--accent-color);
    border-bottom: 3px solid var(--accent-color);
  }
}
