.about {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: var(--content-inner-width);

  &__title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
  }

  &__content {
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: justify;

    &__link {
      border-bottom: 1px solid #000;
      color: #000;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
