.authenticate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authenticate-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 500px;
}

.authenticate-form-input {
  display: block;
}

.authenticate-forgot {
  display: block;
  margin-top: 10px;
  text-align: center;
}

.authenticate-or {
  display: block;
  margin: 20px 0;
  text-align: center;
}

.authenticate-success {
  margin: 20px 0;
  color: green;
  font-size: 12px;
  text-align: center;
}

.authenticate-error {
  margin: 20px 0;
  color: red;
  font-size: 12px;
  text-align: center;
}
