.hamburger {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  width: 35px;
  height: 30px;

  &:hover {
    background: none;
  }

  &::after {
    display: none;
    content: '';
  }

  &__line {
    transition: all 0.3s ease;
    border-radius: 5px;
    background-color: var(--accent-color, #505050);
    width: 35px;
    height: 5px;
  }

  &--active {
    .hamburger__line {
      &--top {
        transform: translateY(12.5px) rotate(225deg);
      }

      &--middle {
        opacity: 0;
        width: 3px;
      }

      &--bottom {
        transform: translateY(-12.5px) rotate(-225deg);
      }
    }
  }
}
