.Marquees {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 25%;
    z-index: 1;
    background: linear-gradient(
      90deg,
      white 0%,
      transparent 20%,
      transparent 80%,
      white 100%
    );
    width: 100vw;
    pointer-events: none;
    content: '';
  }
}

.Marquee {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 200vw;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin: 8rem 0;
    width: 100%;
  }
}

.FirstRow {
  animation: ScrollLeft 20s linear infinite;
  margin-bottom: 2rem;
}

.SecondRow {
  animation: ScrollRight 20s linear infinite;
}

@keyframes ScrollLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-66.6666%);
  }
}

@keyframes ScrollRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(66.6666%);
  }
}
