form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .align-center {
    align-items: center;
  }

  .align-right {
    text-align: right;
  }

  .form-success,
  .form-error {
    margin: 1rem 0;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 1.25rem;
  }

  .form-success {
    background-color: #00bfa5;
    color: #fff;
  }

  .form-error {
    background-color: #d50000;
    color: #fff;
  }

  table {
    border-spacing: 0 10px;
    min-width: min-content;
    max-width: min(600px, calc(100vw - 20px));

    caption {
      color: var(--accent-color);
      font-weight: bold;
      font-size: 28px;
      text-align: left;
    }

    tbody {
      .avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 0;

          border: none;
          border-radius: 50%;
          background-color: transparent;
          padding: 0;

          &:hover {
            border: none;
            background-color: transparent;

            .avatar__button__image {
              opacity: 0.25;
            }

            .avatar__button__label {
              opacity: 1;
            }
          }

          &__image {
            transition: all 0.2s ease-in-out;
            border-radius: 50%;
            width: 8rem;
            height: 8rem;
          }

          &__label {
            position: absolute;

            opacity: 0;

            transition: all 0.2s ease-in-out;
            color: #000;
            font-weight: bold;

            font-size: 1.2rem;
          }
        }
      }

      tr {
        caption {
          margin-bottom: 2rem;
          color: var(--accent-color);
          font-weight: bold;
          font-size: 28px;
          text-align: left;
        }

        td {
          padding: 0.5rem;
          width: 50%;
          text-align: left;

          label,
          input,
          textarea {
            display: block;
          }

          textarea {
            resize: none;
          }

          label {
            margin-bottom: 0.5rem;
            font-weight: bold;
            font-size: 1.2rem;
          }

          input:not([type='checkbox']),
          textarea {
            box-sizing: border-box;
            border: 1px solid #51515100;
            background-color: #f5f5f5;
            padding: 0.5rem;
            width: 100%;

            &:focus {
              outline: none;
              border: 1px solid #515151ff;
              background-color: #ffffff;
            }

            &[type='file'] {
              button {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.form {
  .error {
    color: red;
  }

  &__field {
    width: 50%;

    &__label,
    &__input {
      display: block;
    }

    textarea {
      resize: none;
    }

    &__label {
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 1.2rem;
    }

    &__input {
      box-sizing: border-box;
      border: 1px solid #51515100;
      background-color: #f5f5f5;
      padding: 0.5rem;
      width: 100%;

      &:focus {
        outline: none;
        border: 1px solid #515151ff;
        background-color: #ffffff;
      }

      &__file {
        button {
          display: none;
        }
      }
    }
  }
}
