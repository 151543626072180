.quote-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 2rem 0;
  background-color: var(--accent-color);
  padding: 2rem;
  width: 100%;
  max-width: var(--content-width);

  @media (min-width: 768px) {
    width: 80%;
    min-width: 500px;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }

  caption {
    color: white;
  }

  label {
    color: white;
  }

  button {
    align-self: flex-end;
    transition: all 0.25s ease-in-out;
    border: none;
    background-color: white;
    color: var(--accent-color);

    &::after {
      color: var(--accent-color);
    }
  }

  .privacy-policy {
    input[type='checkbox'],
    label {
      display: inline;
    }

    input[type='checkbox'] {
      margin-right: 0.5rem;
    }

    label {
      color: white;

      a {
        color: white;
        font-size: inherit;
        text-decoration: underline;
      }
    }
  }
}
