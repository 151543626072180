@keyframes slideAndFadeIn {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@mixin slideAndFadeIn($delay: 0, $translateY: 20px) {
  transform: translateY($translateY);
  opacity: 0;
  animation: slideAndFadeIn 0.5s ease-in-out;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}
