@import '../../mixins.scss';

.app-header {
  border: none;
  background-color: transparent;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: -100px;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/talking-performance.appspot.com/o/web%2Fflorian-schmetz-fbCtFV3FkfE-unsplash.jpg?alt=media&token=db30236b-fb4d-402e-895c-2c3c11758bdf),
    url(https://firebasestorage.googleapis.com/v0/b/talking-performance.appspot.com/o/web%2Fthumbs%2Fflorian-schmetz-fbCtFV3FkfE-unsplash_500x500.webp?alt=media&token=461e607c-413f-4a4c-a8a1-f421121c8f4e);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  width: 100vw;
  // min-height: calc(100vh + #{$shape-point-height});
  min-height: 100vh;
  color: #fff;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-items: center;
    width: var(--content-width);
    max-width: 100%;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 50px;
    min-width: min-content;
    max-width: calc(var(--content-width) / 2 - 100px);
    color: #fff;
    text-align: left;

    @media (max-width: 768px) {
      padding-top: 100px;
      width: 100%;
      max-width: 400px;
    }
  }

  &__title p,
  &__subtitle {
    max-width: calc(100vw - 100px);
    hyphens: auto;
  }

  &__title {
    @include slideAndFadeIn(0.5s, 20px);
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 3rem;
  }

  &__subtitle {
    @include slideAndFadeIn(0.75s, 20px);
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: justify;
  }

  &__ctas {
    @include slideAndFadeIn(1s, 20px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    &__cta {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      margin: 0.5rem;
      border: 2px solid #fff;
      background: transparent;
      padding: 0.5rem 1rem;
      color: #fff;
      font-weight: 400;
      font-size: 1rem;

      &::after {
        color: black;
      }

      &:hover {
        border-color: #fff;
        background: #fff;
        color: #000;
      }
    }
  }
}
