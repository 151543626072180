.news {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: var(--content-width);

  @media (max-width: 1200px) {
    padding: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      text-align: center;
    }

    h1 {
      margin: 0;
    }

    .add-button {
      height: 100%;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: stretch;

    gap: 1rem;
    margin-top: 20px;
    padding: 0;
    list-style-type: none;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.no-news {
  margin: 0 1rem;
  margin-top: 20px;

  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #eee;
  padding: 10px;
  font-size: 1.2em;
  text-align: center;

  p {
    margin: 0;
  }
}
