.app-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100px;

  &-inner {
    padding: 0 20px;
    width: 100%;
    max-width: var(--content-width);

    &-upper {
      padding: 20px 0;

      @media (min-width: 768px) {
        padding: 20px 10%;
      }
    }

    &-lower {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      min-height: 100px;
    }
  }

  &-text {
    margin: 0;
    min-width: fit-content;
    color: #666;
    font-size: 12px;
  }

  &-copyright {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: fit-content;
    height: 100%;
  }

  &-social {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-width: fit-content;
    height: 100%;

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 20px;
      width: fit-content;
      height: 100%;
      color: #333;
      font-size: 20px;
    }
  }
}
