.image-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  min-height: 50rem;

  &__image,
  &__details {
    box-sizing: border-box;
  }

  &__image {
    display: block;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 100%;
    height: 100%;

    // Gradient overlay
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      border-radius: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &__details {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0;
    padding: 10px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    &__title {
      margin-bottom: 1rem;
      color: var(--accent-color, #505050);
      color: #fff;
      font-weight: 700;
      font-size: 2.5rem;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
    }

    &__description {
      margin-bottom: 1rem;
      color: var(--accent-color, #505050);
      color: #fff;
      font-weight: 400;
      font-size: 1.25rem;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
    }

    &__button {
      border-color: #fff;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #000;

        &::after {
          color: #000;
        }
      }
    }
  }
}
