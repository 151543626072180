.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;

  &__content {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.loading {
  margin: 20px 0;
  color: #999;
  font-size: 20px;
}
