.news-card {
  $borderWidth: 6px;
  $hoverColor: blue;
  $imageHeight: 300px;

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  border-top: $borderWidth solid;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
  background-color: #eee;
  height: 100%;
  font-size: 1.2em;

  &--news {
    border-color: var(--red);
  }

  &--interview {
    border-color: var(--green);
  }

  &--insight {
    border-color: var(--purple);
  }

  &-link {
    color: inherit;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        text-decoration: none !important;

        .news-card {
          border-color: $hoverColor;

          &__type {
            border-color: $hoverColor;
            background-color: $hoverColor;
            color: #fff;
          }
        }
      }
    }
  }

  &__type {
    position: absolute;
    top: 0;
    left: 0;
    border-right: $borderWidth solid;
    border-bottom: $borderWidth solid;
    border-radius: 0 0 5px 0;
    background-color: #ccc;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    text-transform: uppercase;

    &--news {
      border-color: var(--red);
      background-color: var(--red);
    }

    &--interview {
      border-color: var(--green);
      background-color: var(--green);
    }

    &--insight {
      border-color: var(--purple);
      background-color: var(--purple);
    }
  }

  &__image {
    border-radius: 3px 0 0 3px; // top-left and bottom-left

    background-image: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: $imageHeight;

    &--sr-only {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: calc(100% - $imageHeight);
    overflow: hidden;
    font-size: 1.2em;
    text-align: justify;

    &__title {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 1.5em;
      text-align: justify;
      text-transform: none;

      &::after {
        display: block;
        margin-top: 10px;
        background-color: #ccc;
        width: 50%;
        height: 3px;
        content: '';
      }
    }

    &__preview {
      flex-grow: 1;
      margin-bottom: 10px;
      font-size: 1.2em;
      text-align: justify;
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2em;
      text-align: justify;

      &__date {
        font-size: 1.2em;
        text-align: justify;
      }

      &__author {
        font-size: 1.2em;
        text-align: justify;
      }
    }
  }
}

.horizontal-divider {
}
