.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }

  &__divider {
    margin: 0 0 20px 0;
    background-color: #e0e0e0;
    width: 100%;
    height: 1px;
  }

  &__spacer {
    width: 100%;
    height: 60px;
  }
}
