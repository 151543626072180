.editable {
  position: relative;
  box-sizing: border-box;

  width: fit-content;
  height: fit-content;

  @media (max-width: 768px) {
    &__controls {
      display: flex;
      opacity: 0.8;
      pointer-events: auto;

      &__button {
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  &--inline {
    word-wrap: nowrap;
    display: inline-block;
    padding: 0;
    width: min-content;
    white-space: nowrap;

    .editable__content {
      padding: 0;
      min-width: 0;
      min-height: 0;
    }
  }

  &--hidden {
    display: none;
  }

  &__content,
  &__input {
    color: inherit;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    text-align: inherit;
  }

  &__content {
    min-width: 200px;
    min-height: 20px;
    white-space: pre-line; // preserve line breaks
  }

  &__input {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
    height: 100%;
    resize: none;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;

    &:focus {
      outline: none;
    }

    &:hover {
      border: none;
      background-color: transparent;
    }
  }

  &__controls {
    display: none;

    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &__button {
      display: inline-block;
      cursor: pointer;
      margin: 5px;
      border: none;
      border-radius: 50%;
      background-color: var(--accent-color);
      padding: 0;
      aspect-ratio: 1/1;
      width: 3rem;
      height: 3rem;
      color: #fff;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;

      &::after {
        display: none;
        content: '';
      }

      &:hover {
        border-color: var(--accent-color-dark);
        background-color: var(--accent-color-dark);
        color: #fff;
        text-decoration: none;

        &::after {
          display: none;
          content: '';
        }
      }
    }
  }

  &:hover &__controls {
    display: flex;
    pointer-events: auto;
  }

  &:hover,
  &--editing {
    box-shadow: inset 0px 0px 0px 2px var(--accent-color);

    :has(.editable__controls__button--edit:hover) {
      box-shadow: inset 0px 0px 0px 2px var(--accent-color-dark);
      background-color: #ffffff80;
    }

    &:has(.editable__controls__button--cancel:hover) {
      box-shadow: inset 0px 0px 0px 2px red;
      background-color: #ff000080;
    }

    &:has(.editable__controls__button--save:hover) {
      box-shadow: inset 0px 0px 0px 2px green;
      background-color: #00ff0080;
    }

    &:has(.editable__controls__button--reset:hover) {
      box-shadow: inset 0px 0px 0px 2px orange;
      background-color: #ffa50080;
    }
  }

  &--editing &__controls {
    display: flex;
    top: -4rem;
    width: 100%;
    height: initial;
    pointer-events: auto;
  }
}
