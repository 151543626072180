.article {
  --border-width: 6px;
  --article-width: 800px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border: var(--border-width) solid transparent;
  padding: 20px;
  width: 100%;
  max-width: var(--article-width);

  &--news {
    border-top-color: var(--accent-color);
  }

  &--interview {
    border-top-color: var(--purple);
  }

  &--insight {
    border-top-color: var(--green);
  }

  &__image {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: var(--article-width);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    margin: 0;
    margin-top: 30px;
    padding: 0;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
  }

  &__meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    padding: 0;
    width: 100%;
    max-width: var(--article-width);
    font-size: 1.2rem;

    &__author {
      margin: 0;
      padding: 0;
      font-weight: 700;
    }

    &__date {
      margin: 0;
      padding: 0;
    }
  }

  &__content {
    word-wrap: break-word;
    margin: 0;
    padding: 0;
    width: 100%;
    height: fit-content;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: justify;

    @media (min-width: var(--article-width)) {
      font-size: 1.4rem;
    }

    .editable--editing {
      &__content,
      &__input {
      }
    }
  }
}

.separator {
  margin: 0;
  background-color: #e0e0e0;
  padding: 0;
  width: 100%;
  max-width: var(--article-width);
  height: 1px;
}
