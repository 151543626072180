.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 100px;

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 100px;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(30px);

    opacity: 0;
    z-index: 1000;
    transition: all 0.3s ease;
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 100px);
    pointer-events: none;
  }

  &--active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }
}
