$image-width: 400px;
$background-opacity: 0.5;

.founder-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition-duration: 0.25s;
  margin: 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 1000px;
  min-height: 400px;

  @media (max-width: 768px) {
    gap: 0;
    max-width: 100%;
    min-height: 0;

    .founder-card__image {
      width: 100%;
      max-width: 100%;

      img {
        width: 100%;
        max-width: 100%;
        max-height: 400px;
      }
    }

    .founder-card__content {
      width: 100%;
      max-width: 100%;
      overflow-y: visible;

      &__name {
        margin-top: 1rem;
      }
    }
  }

  &__image {
    margin: 0;
    max-width: $image-width;

    img {
      border-radius: 0.5rem;
      max-width: $image-width;
      object-fit: cover;
      object-position: top;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: calc(100% - #{$image-width});

    &__name {
      margin: 0;
      font-weight: 700;
      font-size: 2.25rem;
      text-align: center;
    }

    &__title {
      margin: 0;
      font-weight: 400;
      font-size: 1.75rem;
      text-align: center;
    }

    &__bio {
      margin: 0;
      color: #000040;
      font-weight: 400;
      font-size: 1.5rem;
      text-align: justify;
    }

    &__links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      margin: 0;
      padding: 1rem;
      list-style: none;

      :nth-child(even) .founder-card__content__links__item__link {
        flex-direction: row-reverse;
        text-align: right;
      }

      &__item {
        flex-basis: 100%;

        &__link {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          margin: 0;
          border-radius: 0.5rem !important;
          padding: 0.5rem !important;
          width: 100%;
          max-width: 100%;
          color: var(--accent-color) !important;
          font-weight: 700;

          &:hover {
            outline: 2px solid var(--accent-color-dark);
            color: var(--accent-color-dark) !important;
            text-decoration: none !important;
          }

          svg {
            border-bottom: 2px solid #fff;
            padding-bottom: 0.25rem;
            font-size: 2.5rem;
          }

          &__text {
            font-size: 1.25rem;
            font-family: monospace;
          }
        }
      }
    }
  }
}
