.app-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--content-width);
    height: 100px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    height: 100%;

    &__image {
      height: 100px;

      @media (max-width: 768px) {
        height: 70px;
      }
    }
  }

  &__hamburger {
    display: none;
    margin: 1rem;

    @media (max-width: 768px) {
      display: flex;
    }
  }
}
