@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Montserrat:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');

:root {
  --content-width: 1400px;
  --content-inner-width: 1000px;
  --accent-color: #5891fe;
  --accent-color-dark: #3a6ed1;
  --red: #ff7675;
  --purple: #6c5ce7;
  --green: #00b894;

  --accent-bg: #e5edff;
  --complementary-color: #ff8773;
  --complementary-color-dark: #d15f4f;

  --font-family-primary: 'Montserrat', sans-serif;
  --font-family-secondary: 'Barlow Condensed', sans-serif;

  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);

  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body > p {
  display: none;
}

body:has(.navbar--active) {
  height: 100vh;
  overflow-y: hidden;
}

.layout-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  padding: 30px;

  width: 100%;
  max-width: var(--content-width);
  min-height: calc(100vh - 100px - 100px);

  @media (max-width: 768px) {
    padding: 0;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
caption {
  margin-bottom: 1em;
  // color: #505050;
  color: var(--accent-color, #505050);
  font-family: 'Noto Sans', 'Montserrat', sans-serif;
  text-transform: uppercase;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
.button {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  // border: 2px solid var(--accent-color);
  border: 2px solid var(--accent-color);
  border-radius: 0;
  background-color: transparent;
  padding: 8px 16px;
  color: var(--accent-color);
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    border-color: var(--accent-color-dark);
    background-color: var(--accent-color-dark);
    color: #fff;
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: #ccc;
    background-color: #ccc;

    &:hover {
      border-color: #ccc;
      background-color: #eee;
    }
  }
}

.button-animated {
  &::after {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    margin-left: -0.75rem;
    content: '→';
    color: #fff;
  }

  &:hover {
    &::after {
      opacity: 1;
      margin-left: 0.5rem;
    }
  }
}

.image-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;

    border: none;
    border-radius: 50%;
    background-color: transparent;
    padding: 0;

    &__image {
      transition: all 0.2s ease-in-out;
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
      object-fit: cover;
    }

    &__label {
      position: absolute;

      opacity: 0;

      transition: all 0.2s ease-in-out;
      color: #000;
      font-weight: bold;

      font-size: 1.2rem;
    }

    &:hover {
      border: none;
      background-color: transparent;

      .form__avatar__button__image {
        opacity: 0.25;
      }

      .form__avatar__button__label {
        opacity: 1;
      }
    }
  }
}

a:not(.button),
button.link {
  transition: none;
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  color: var(--accent-color-dark);
  font-size: 12px;

  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: var(--accent-color);
    text-decoration: underline;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  h1 {
    display: inline-block;
    margin: 0;
  }

  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #eee;
    padding: 0;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: #ccc;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

input,
textarea {
  transition: all 0.2s ease-in-out;
  border: 1px solid #ccc;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 8px 16px;
  font-size: 16px;

  &[type='date'] {
    padding: 6px 16px;
  }

  &:hover {
    border: 1px solid #999;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: #eee;
  }

  &:invalid:focus {
    border-color: #f00;
  }
}

// <labels> directly under any element which has any child with a :required attribute set to true
:has(:required) > label {
  &::after {
    content: ' *';
    color: #f00;
  }
}

div.select {
  display: grid;
  position: relative;

  grid-template-areas: 'select';
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  background-color: #fff;
  padding: 0.25em 0.5em;
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  font-size: 1.25rem;
  line-height: 1.1;

  &::after {
    justify-self: end;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    background-color: var(--select-arrow);
    width: 0.8em;
    height: 0.5em;
    content: '';
  }

  &:focus + .focus {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }

  select,
  &::after {
    grid-area: select;
  }

  select {
    appearance: none;
    cursor: inherit;
    margin: 0;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0 1em 0 0;
    width: 100%;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;

    &::-ms-expand {
      display: none;
    }
  }
}
