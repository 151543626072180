$border-color: #ffbdbd;
$error-border: #ff9999;

.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0.5rem;
  border: 2px solid $border-color;
  border-radius: 0.5rem;
  background-color: #ffcaca;
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;

  &__title,
  &__details,
  &__summary,
  &__buttons__button {
    color: #ff0000;
  }

  &__title {
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.5rem;
  }

  &__details {
    margin-bottom: 0.5rem; // <details> is a HTML5 element
    padding: 1rem 0.25rem;
    max-width: min(100%, 500px);
    font-size: 1rem;
    text-align: left;

    &__summary {
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 1.25rem;
    }

    code {
      display: block;
      border-radius: 0.25rem;
      background-color: #f5f5f5;
      padding: 0.5rem;
      overflow: auto;
      font-size: 0.875rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    max-width: min(100%, 500px);

    &__button {
      border-color: #ff0000;

      &:hover {
        border-color: #ff0000;
        background-color: #ff0000;
        color: #fff;
        text-decoration: none;

        &::after {
          display: none;
          content: '';
        }

        &__icon {
          color: #fff;
        }

        &__text {
          color: #fff;
        }

        &__icon,
        &__text {
          text-decoration: none;
        }
      }
    }
  }
}
