.speaker-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(1rem, 1rem);
  box-sizing: border-box;
  outline: #9aaffc dashed 2px;
  padding: 2rem;
  width: 30rem;
  min-height: 12rem;

  @media (max-width: 768px) {
    width: 80%;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-0.5rem, -0.5rem);
    z-index: -1;
    background-color: #cbd6ff;
    width: 100%;
    height: 100%;
    content: '';
  }

  &__image {
    position: absolute;
    transform: translate(-4rem, -4rem);
    border-radius: 50%;
    background-color: #fff;
    width: 6rem;
    height: 6rem;
    object-fit: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3rem;

    &__name {
      margin: 0;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
    }

    &__rates {
      margin-bottom: 0.5rem;
      color: #cf28ab;
      font-size: 1.2rem;
      font-family: 'Roboto Mono', monospace;

      &__separator {
        margin: 0 0.5rem;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;

    &__delete {
      border-color: #ff3838;
      color: #ff3838;

      &:hover {
        border-color: #ff3838;
        background-color: #ff3838;
        color: #fff;
      }
    }
  }
}
