/* 404 page */
.error {
  background: #fff;
  padding: 20px;
  color: #000;
  text-align: center;
}

.error-description {
  margin: 0 0 20px 0;
  font-size: 20px;
}

.error-link {
  margin: 0 0 20px 0;
  font-size: 20px;
  text-decoration: underline;
}
